import { useMachine } from "@xstate/react";
import * as React from "react";
import { MouseEventHandler, useEffect, useMemo } from "react";
import { createMachine } from "xstate";

type ButtonProps = {
  type?: "button" | "submit" | "reset" | undefined;
  label: string;
  busy?: boolean;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
};

const actionBtnMachine = createMachine({
  initial: "idle",
  states: {
    idle: {
      on: {
        BUSY: { target: "busy" },
      },
    },
    busy: {
      on: {
        DONE: "done",
      },
    },
    done: {
      type: "final",
    },
  },
  predictableActionArguments: true,
});

export const ActionButton = ({
  type = "button",
  label,
  onClick = undefined,
  disabled = false,
  busy = false,
  className = "",
}: ButtonProps) => {
  const [state, send] = useMachine(actionBtnMachine);

  useEffect(() => {
    if (busy) {
      send({ type: "BUSY" });
    } else {
      send({ type: "DONE" });
    }
  }, [send, busy]);

  const isBusy = useMemo(() => state.value === "busy", [state.value]);
  const isDisabled = useMemo(() => isBusy || disabled, [isBusy, disabled]);

  return (
    <button
      disabled={isDisabled}
      tabIndex={0}
      type={type}
      className={`bg-black text-white font-medium my-1 py-[0.85rem] px-12 rounded-xl text-lg hover:opacity-80 cursor-pointer transition-all hover:shadow-md focus:ring-2 focus:ring-light-green focus:shadow-md ${className}`}
      onClick={onClick}
    >
      {isBusy ? "..." : label}
    </button>
  );
};

export const ActionButtonAlt = ({ type = "button", label, onClick }: ButtonProps) => (
  <button
    tabIndex={0}
    type={type}
    className="bg-transparent font-medium my-1 py-2 px-12 rounded-xl text-lg text-light-blue hover:opacity-80 cursor-pointer transition-all hover:shadow-md focus:ring-2 focus:ring-light-green focus:shadow-md md:mr-6"
    onClick={onClick}
  >
    {label}
  </button>
);
