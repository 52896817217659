import * as path from "path";
import { createExternalPage, createRegularPage } from "../../page-utils";
import { localePrefix } from "../../pages";
import { Locale } from "../../types/Locale";
import { Layout } from "../../utils/layout";

export const OnboardingGenderPage = createExternalPage({
  name: "onboardingGender",
  path: (locale) => `${localePrefix[locale]}/onboarding/gender`,
});

export const OnboardingSuccessPage = createRegularPage({
  name: "onboardingSuccess",
  component: path.resolve("./src/ecosystems/onboarding/components/OnboardingSuccess.tsx"),
  layout: Layout.NO_FOOTER,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
      case Locale.ES:
      case Locale.SV:
        return `${localePrefix[locale]}/onboarding-success`;
    }
  },
});
