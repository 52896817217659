import { IMembershipSubscriptionProvider, IMembershipSubscriptionStatus } from "@dietdoctor/elements";
import { RouteComponentProps, Router } from "@reach/router";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import JoinGroup from "../../../graphql/DDPro/JoinGroup.graphql";
import { OnboardingGenderPage } from "../../onboarding/pages";
import {
  DDProJoinPage as PageDefinition,
  GetPageContext,
  HomePage,
  LoggedInHomePage,
  LoginPage,
  PaymentPage,
} from "../../../pages";
import { client } from "../../../utils/apollo-client";
import { isBrowser } from "../../../utils/ssr";
import { tryOrUndefined } from "../../../utils/utils";
import { useNavigate } from "../../../components/Link/Link";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { ActionButton } from "../form/ActionButton";
import { useDDProTranslations } from "../useDDProTranslations";
import { useUser } from "../../../hooks/useUser/useUser";
import { JoinGroupFromEmailPayload, MessagePayload } from "./machine";
import { b64_to_utf8, CheckPage, ConfirmPage, getUrl } from "./utils";

type PageDefinitionType = GetPageContext<typeof PageDefinition>;
type SubscriptionStatus = IMembershipSubscriptionStatus | undefined;
type SubscriptionProvider = IMembershipSubscriptionProvider | undefined;

// ts-prune-ignore-next
export default function DDProJoinCheckExisting() {
  if (!isBrowser()) return null;

  const { locale } = useContext<PageDefinitionType>(pageContext);

  return (
    <div className="m-auto md:max-w-screen-xl ">
      <SEOReactHelmet title={`Diet Doctor - DD Pro`} lang={locale as string} />
      <div className="py-6">
        <Router basepath="/pro/user-join">
          <ConfirmSubscriptionTransfer path="/confirm" />
          <CheckSubscription path="/check" />
          <ToCheckOrLogin path="/" />
        </Router>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckSubscription = (_: RouteComponentProps) => {
  const navigate = useNavigate();
  const { user, loading } = useUser();
  const { query } = useContext<PageDefinitionType>(pageContext);
  const { m: message, s: signedUrl } = query ?? { m: "", s: "" };
  const decoded = b64_to_utf8(message ?? "");
  const payload = tryOrUndefined<MessagePayload>(() => JSON.parse(decoded!));

  const isAdminPays = useMemo<boolean>(() => payload?.subscription_payer === 1, [payload?.subscription_payer]);

  const subscriptionStatus = useMemo<SubscriptionStatus>(() => user?.membershipSubscription.status, [user]);

  const subscriptionProvider = useMemo<SubscriptionProvider>(
    () => user?.membershipSubscription.provider,
    [user]
  );

  useEffect(() => {
    if (!loading) {
      if (subscriptionStatus !== undefined) {
        if (isAdminPays) {
          if (subscriptionProvider !== "GENERIC") {
            // admin pays with existing subscription
            navigate({ to: ConfirmPage, query: { m: message, s: signedUrl } });
          } else {
            // admin pays without existing subscription
            navigate({ to: OnboardingGenderPage });
          }
        } else {
          // member pays
          if (subscriptionStatus === "ACTIVE") {
            // member pays with subscription
            navigate({ to: LoggedInHomePage });
          } else {
            // member pays without subscription
            navigate({ to: PaymentPage });
          }
        }
      }
    }
  }, [isAdminPays, subscriptionStatus, loading, subscriptionProvider, navigate, message, signedUrl]);

  return null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ConfirmSubscriptionTransfer = (_: RouteComponentProps) => {
  const navigate = useNavigate();
  const [isBusy, setBusy] = useState<boolean>(false);
  const { query } = useContext<PageDefinitionType>(pageContext);
  const { m: message } = query ?? { m: undefined };
  const decoded = b64_to_utf8(message ?? "");
  const payload = tryOrUndefined<MessagePayload>(() => JSON.parse(decoded!));

  const input: JoinGroupFromEmailPayload = {
    firstName: payload?.first_name ?? "",
    lastName: payload?.last_name ?? "",
    password: "notused",
    signedURL: getUrl(payload?.url_host_path ?? ""),
    taxResidence: "",
  };

  setBusy(true);

  client
    .mutate({
      mutation: JoinGroup,
      variables: {
        input,
      },
    })
    .catch((e) => {
      console.error(e);
      setBusy(false);
    });

  const handleMutation = useCallback(() => {
    navigate({ to: HomePage });
  }, [navigate]);

  const tt = useDDProTranslations();

  return (
    <div className="w-full flex flex-col px-4 md:px-0">
      <div className="flex flex-col justify-between mb-4 md:flex-row mt-8 md:px-3">
        <h1 className="font-normal text-4xl m-0 mb-4 md:mb-0 flex">
          {tt.yourSubscriptionHasBeenSuccessfullyTransferred}
        </h1>
      </div>
      <div className="py-6 md:px-3">
        From now on, your clinician will take care of your subscription cost, and you will no longer be charged
        for it.
      </div>
      <div className="mt-4 md:px-3">
        <ActionButton type="submit" busy={isBusy} label="Go to my homepage" onClick={handleMutation} />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToCheckOrLogin = (_: RouteComponentProps) => {
  const { user, loggedIn, loading } = useUser();
  const navigate = useNavigate();
  const baseUrl = process.env.GATSBY_WEBSITE_URL || window.location.origin;
  const { query } = useContext<GetPageContext<typeof PageDefinition>>(pageContext);
  const { m: message, s: signedURL } = query ?? { m: "", s: "" };

  useEffect(() => {
    // if not logged in
    if (!loading && !user) {
      navigate({
        to: LoginPage,
        query: {
          redirectTo: `${baseUrl}/pro/user-join/check%3Fm=${message}%26s=${signedURL}`,
        },
      });
    } else if (!loading && user) {
      navigate({
        to: CheckPage,
        query: {
          m: message,
          s: signedURL,
        },
      });
    }
  }, [user, loading, loggedIn, navigate, baseUrl, message, signedURL]);

  return null;
};
